<ion-header>
  <ion-toolbar>
    <ion-title>
      Unauthenticated Login
    </ion-title>
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()">
        <ion-icon [className]="!IOS ? 'show-div' : 'hide-div'" name="arrow-back-circle"></ion-icon>
        <ion-text [className]="IOS ? 'show-div' : 'hide-div'" color="primary">Back</ion-text>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-list>
    <div [className]="step == 0 ? 'show-div' : 'hide-div'">
      <ion-item style="margin-bottom: 40px">
        <ion-text>
          <p>This is a feature to allow two classes of people to gain access to recent visits data, so they can find out how the person receiving care services appeared at the last visit, and what meds were taken / refused:</p>
          <ol><li>
          Health professionals, such as ambulance staff</li><li>
          Individuals with explicit permission from the person receiving care services</li></ol>
          <p><strong>If you are not in one of those two groups you must not proceed.</strong></p>
        </ion-text>
      </ion-item>
      <ion-item>
        <ion-input id="email-input" #emailInput type="email" [(ngModel)]="email" autocomplete="email" autofocus (ionChange)="checkEmail()" placeholder="Enter your email"></ion-input>
      </ion-item>
      <ion-item id="email-btn" button="true" color="primary" (click)="logEmail()" [disabled]="buttonDisabled">
        OK
      </ion-item>
    </div>
    <div [className]="step == 1 ? 'show-div' : 'hide-div'">
      <ion-item lines="none">
        We have sent you an email containing a six digit PIN.  It may take a minute or two to arrive.  When you get it please enter the PIN below.
      </ion-item>
      <ion-item>
        <ion-input id="pin-input" #pinInput inputmode="numeric" [(ngModel)]="pin" (ionChange)="checkPIN()" placeholder="Enter the PIN"></ion-input><br />
        <div [className]="showPINError ? 'show-div' : 'hide-div'">
          <p style="color: red">Incorrect PIN - please try again</p>
        </div>
      </ion-item>
    </div>
  </ion-list>
</ion-content>


