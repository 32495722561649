import { Injectable, NgZone } from "@angular/core";
import { Gesture, GestureController, IonCard } from "@ionic/angular";
import { ONE_HOUR, ONE_MINUTE, ONE_WEEK } from "../constants";
import { IMedicationAction } from "../models/people/user";

export const ShortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const LongMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const LongDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const shortDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const legacyMedicationActions: IMedicationAction[] = [
  { description: "Administer", trackMeds: true, leaveOutMeds: undefined, displayAction: false },
  { description: "Prompt", trackMeds: false, displayAction: true },
  { description: "Assist", trackMeds: false, displayAction: true },
];

export interface IWeekDef {
  desc: string;
  isThisNextOrLast: boolean;
}

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  constructor(private gestureCtrl: GestureController, private zone: NgZone) {}

  public startOfDay(aDate: Date): number {
    return new Date(aDate).setHours(0, 0, 0, 0);
  }

  private optionalYearAndTime(d: Date, includeYear: boolean, includeTime: boolean): string {
    let result = "";
    if (includeYear) {
      result += " " + d.getFullYear();
    }
    if (includeTime) {
      result += " " + this.getTimeOfDayStr(d);
    }
    return result;
  }

  public stringsToParagraphs(strings: string[]): string {
    return strings.map((s) => `<p>${s}</p>`).join("");
  }

  public formatDateShort(d: Date, includeYear: boolean, includeTime: boolean): string {
    return (
      `${d.getDate().toString().padStart(2, "0")} ${ShortMonths[d.getMonth()]}` + // 03 Jan
      this.optionalYearAndTime(d, includeYear, includeTime)
    );
  }

  public doATimeAgoCalc(since: Date): string {
    let retVal = "just now";
    const timeAgoInMs = new Date().valueOf() - since.valueOf();
    if (timeAgoInMs >= ONE_HOUR) {
      if (timeAgoInMs >= ONE_HOUR * 2) {
        retVal = `${Math.floor(timeAgoInMs / ONE_HOUR)} hours ago`;
      } else {
        retVal = "an hour ago";
      }
    } else if (timeAgoInMs >= ONE_MINUTE) {
      if (timeAgoInMs >= ONE_MINUTE * 2) {
        retVal = `${Math.floor(timeAgoInMs / ONE_MINUTE)} minutes ago`;
      } else {
        retVal = "a minute ago";
      }
    }
    return retVal;
  }

  public formatDateMed(d: Date, includeYear: boolean, includeTime: boolean): string {
    return (
      `${shortDays[d.getDay()]} ${ShortMonths[d.getMonth()]} ${d.getDate().toString()}${this.nth(d.getDate())}` + // Fri Jan 3rd
      this.optionalYearAndTime(d, includeYear, includeTime)
    );
  }

  public formatDateLong(d: Date, includeYear: boolean, includeTime: boolean): string {
    return (
      `${LongDays[d.getDay()]} ${LongMonths[d.getMonth()]} ${d.getDate().toString()}${this.nth(d.getDate())}` + // Friday January 3rd
      this.optionalYearAndTime(d, includeYear, includeTime)
    );
  }

  public formatDtForDebug(d: Date): string {
    return `${d.getDate().toString().padStart(2, "0")} ${ShortMonths[d.getMonth()]} ${this.getTimeOfDayStr(d)}`; // DD MMM HH:mm
  }

  public qrExpiryStringToDate(str: string): Date {
    return new Date(`20${str.slice(0, 2)}-${str.slice(2, 4)}-${str.slice(4, 6)}T${str.slice(6,8)}:${str.slice(8, 10)}:00Z`)
  }

  public startOfWeek(aDate: Date): number {
    const day = aDate.getDay() || 7;
    if (day !== 1) {
      return new Date(aDate).setHours(-24 * (day - 1), 0, 0, 0);
    } else {
      return this.startOfDay(aDate);
    }
  }

  public getTimeOfDayStr(d: Date): string {
    return `${d.getHours().toString().padStart(2, "0")}:${d.getMinutes().toString().padStart(2, "0")}`; // HH:mm
  }

  public describeDayOfWeek(aDate: Date): string {
    return shortDays[aDate.getDay()];
  }

  public countWords(s: string): number {
    if (!s || s.trim() === "") {
      return 0;
    }
    s = s.replace(/\n/g, " "); // newlines to space
    s = s.replace(/(^\s*)|(\s*$)/gi, ""); // remove spaces from start + end
    s = s.replace(/[ ]{2,}/gi, " "); // 2 or more spaces to 1
    return s.split(" ").length;
  }

  public duration(from: Date, to: Date): number {
    const durationInMs = new Date(to).valueOf() - new Date(from).valueOf();
    return durationInMs / ONE_MINUTE;
  }

  public minsToDaysHoursAndMinsStr(mins: number): string {
    let result = "";
    function addOne(num: number, label: string, delimiter = ""): void {
      if (num > 0) {
        if (result !== "") {
          if (delimiter !== "") {
            result += " " + delimiter + " ";
          }
        }
        result += `${num} ${label}${num > 1 ? "s" : ""}`;
      }
    }
    const hours = Math.floor(mins / 60);
    const minsLeft = mins % 60;
    const days = Math.floor(hours / 24);
    const hoursLeft = hours % 24;
    addOne(days, "day");
    addOne(hoursLeft, "hour");
    addOne(minsLeft, "minute", "and");
    return result;
  }

  public nth(d: number): string {
    if (d > 3 && d < 21) {
      return "th";
    }
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  public daysFromNow(dt: Date): number {
    const startOfDtDay = this.startOfDay(dt);
    const startOfToday = this.startOfDay(new Date());
    const result = (startOfDtDay - startOfToday) / (1000 * 60 * 60 * 24);
    // if dt is in dst but the current date isn't, or vice versa, then
    // we'll end up with a non-integer result.  work around this, rounding
    // in the "correct" direction.  i'm sure there's a neater way to achieve this.
    const ceil = Math.ceil(result);
    if (result === ceil) {
      return ceil;
    }
    const floor = Math.floor(result);
    if (Math.abs(ceil - result) < Math.abs(floor - result)) {
      return ceil;
    } else {
      return floor;
    }
  }

  public getShortRelativeWeekDef(currentWeekBeginning: number, theirWeekBeginning: number): IWeekDef {
    let desc: string;
    let isThisNextOrLast: boolean;
    if (theirWeekBeginning === currentWeekBeginning) {
      desc = "This";
      isThisNextOrLast = true;
    } else if (theirWeekBeginning === currentWeekBeginning - ONE_WEEK) {
      desc = "Last";
      isThisNextOrLast = true;
    } else if (theirWeekBeginning === currentWeekBeginning + ONE_WEEK) {
      desc = "Next";
      isThisNextOrLast = true;
    } else {
      const wb = new Date(theirWeekBeginning);
      desc = `${ShortMonths[wb.getMonth()]} ${wb.getDate()}${this.nth(wb.getDate())}`;
      isThisNextOrLast = false;
    }
    return { desc, isThisNextOrLast };
  }

  public getFullRelativeWeekDef(currentWeekBeginning: number, theirWeekBeginning: number): IWeekDef {
    const boundary = this.getShortRelativeWeekDef(currentWeekBeginning, theirWeekBeginning);
    if (boundary.isThisNextOrLast) {
      boundary.desc += " Week"; // "This Week", "Last Week", "Next Week"
    } else {
      boundary.desc = "w/c " + boundary.desc; // "w/c Jun 15th"
    }
    return boundary;
  }

  public getShortRelativeWeekDefForDate(dt: Date): IWeekDef {
    return this.getShortRelativeWeekDef(this.startOfWeek(new Date()), this.startOfWeek(dt));
  }

  public getFullRelativeWeekDefForDate(dt: Date): IWeekDef {
    return this.getFullRelativeWeekDef(this.startOfWeek(new Date()), this.startOfWeek(dt));
  }

  public describeWeekContainingDate(dt: Date): string {
    const weekDef = this.getFullRelativeWeekDefForDate(dt);
    return weekDef.desc;
  }

  public describeDtRelativeToThisWeek(dt: Date): string {
    const timeStr = this.getTimeOfDayStr(dt);
    const daysFromNow = this.daysFromNow(dt);
    if (daysFromNow === -1) {
      return `${timeStr} yesterday`;
    } else if (daysFromNow === 1) {
      return `${timeStr} tomorrow`;
    } else if (daysFromNow === 0) {
      return `${timeStr} today`;
    }
    const weekDef = this.getShortRelativeWeekDefForDate(dt);
    const dayStr = this.describeDayOfWeek(dt);
    if (weekDef.isThisNextOrLast) {
      return `${weekDef.desc} ${dayStr} at ${timeStr}`; // Last Tue at 10:00 / This Sun at 18:00
    } else {
      return `${timeStr} on ${dayStr} ${weekDef.desc}`; // 10:00 on Tue Jun 4th
    }
  }

  public describeDtRelativeToToday(dt: Date, identifyToday: boolean, omitMidnight = false): string {
    let result = this.getTimeOfDayStr(dt);
    if (omitMidnight && result === "00:00") {
      result = "";
    }
    const daysFromNow = this.daysFromNow(dt);
    if (daysFromNow <= -2) {
      result += ` ${Math.abs(daysFromNow)} days ago`;
    } else if (daysFromNow === -1) {
      result += " yesterday";
    } else if (daysFromNow === 1) {
      result += " tomorrow";
    } else if (daysFromNow >= 2) {
      result += ` in ${Math.abs(daysFromNow)} days' time`;
    } else if (daysFromNow === 0 && identifyToday) {
      result += " today";
    }
    return result.trim();
  }

  public delay(ms: number) {
    return new Promise((res) => setTimeout(res, ms));
  }

  public earliestDate(dates: Date[]): Date {
    if (!dates || dates.length === 0) {
      return;
    }
    let earliest = dates[0];
    for (let i = 1; i < dates.length; i++) {
      if (dates[i] < earliest) {
        earliest = dates[i];
      }
    }
    return earliest;
  }

  public latestDate(dates: Date[]): Date {
    if (!dates || dates.length === 0) {
      return;
    }
    let latest = dates[0];
    for (let i = 1; i < dates.length; i++) {
      if (dates[i] > latest) {
        latest = dates[i];
      }
    }
    return latest;
  }

  public listArrayContents(array: string[]): string {
    let result = "";
    for (let i = 0; i < array.length; i++) {
      if (i > 0) {
        if (i === array.length - 1) {
          result += " & ";
        } else {
          result += ", ";
        }
      }
      result += array[i];
    }
    return result;
  }

  public getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    function deg2rad(deg) {
      return deg * (Math.PI / 180);
    }

    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  public commentsToSummaryTag(
    comments: string,
    needsAdditionalPadding: boolean,
    maxLengthForUnsummarised = 50
  ): string {
    comments = comments.replace(/(?:\r\n|\r|\n)/g, "<br>");
    if (comments.length <= 40) {
      return comments;
    }
    let summary = comments.substring(0, maxLengthForUnsummarised - 3);
    const lastspace = summary.lastIndexOf(" ");
    summary = comments.substring(0, lastspace) + "...";
    const details = "..." + comments.substring(lastspace + 1);
    const extraPadding = needsAdditionalPadding ? "extra-padding" : "";
    return `<details class="comments ${extraPadding}"><summary>${summary}</summary>${details}</details>`;
  }

  public makeCardSwipable(card: IonCard, canStartCallback: () => boolean, onSwipeCallback: () => void): void {
    if (!card) {
      return;
    }
    const el = (card as any).el;
    const gesture: Gesture = this.gestureCtrl.create({
      el,
      gestureName: "swipe",
      canStart: () => {
        return !!canStartCallback(); // the !! is just in case it returns undefined
      },
      onMove: (ev) => {
        if (ev.deltaX > 0) {
          el.style.transform = `translateX(${ev.deltaX}px)`;
        }
      },
      onEnd: (ev) => {
        if (ev.deltaX > 150) {
          this.zone.run(() => {
            onSwipeCallback();
          });
        }
        el.style.transform = "";
      },
    });
    gesture.enable(true);
  }

  public setUpLongPress(
    el: HTMLElement,
    minLengthMs: number,
    onLongPressComplete: (el: HTMLElement) => void,
    onClick?: (el: HTMLElement) => void
  ): void {
    if (!el) {
      return;
    }
    let startedAt: number;
    let preventClick: boolean;
    // depending upon what type of device we are running on, we might need to respond to mousedown, touchstart or pointerdown and
    // mouseup, touchend of pointerup.
    // some devices may fire more than one event type, so we should use preventDefault() to avoid anything happening more than once
    function startLongTouch(ev) {
      ev.preventDefault();
      startedAt = new Date().valueOf();
      preventClick = false;
    }
    const boundStartLongTouch = startLongTouch.bind(this);
    el.onmousedown = boundStartLongTouch;
    el.ontouchstart = boundStartLongTouch;
    el.onpointerdown = boundStartLongTouch;
    function endLogTouch(ev) {
      ev.preventDefault();
      if (!startedAt) {
        return; // shouldn't happen
      }
      const lengthOfPress = new Date().valueOf() - startedAt;
      startedAt = undefined;
      if (lengthOfPress >= minLengthMs) {
        preventClick = true;
        this.zone.run(() => {
          onLongPressComplete(el);
        });
      }
    }
    const boundEndLongTouch = endLogTouch.bind(this);
    el.onmouseup = boundEndLongTouch;
    el.ontouchend = boundEndLongTouch;
    el.onpointerup = boundEndLongTouch;
    function newOnClick(ev) {
      if (preventClick) {
        return ev.preventDefault(); // if it's a long press, it's not a click!
      }
      if (onClick) {
        this.zone.run(() => {
          onClick(el);
        });
      }
    }
    const boundNewOnClick = newOnClick.bind(this);
    el.onclick = boundNewOnClick;
  }
}

export function getTZOffset(timeZone = "UTC", date = new Date()): number {
  const utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));
  const tzDate = new Date(date.toLocaleString("en-US", { timeZone }));
  return (utcDate.getTime() - tzDate.getTime()) / 6e4;
}
