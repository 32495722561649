import { Component, Input, ViewChild } from "@angular/core";
import { IonInput, ModalController, Platform } from "@ionic/angular";
import { BackendService, ISimpleHeader, NoNetworkAction } from "../../services/backend.service";
import { ObservableEventsService } from "../../services/observableEventsService";
import { DialogService } from "../../services/dialogService";
import { UtilsService } from "../../services/utilsService";

const EMAIL_REGEX =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

@Component({
  templateUrl: "./unauthenticated-login.page.html",
  styleUrls: ["./unauthenticated-login.page.scss"],
})
export class UnauthenticatedLoginPage {
  @ViewChild("emailInput") emailInput: IonInput;
  @ViewChild("pinInput") pinInput: IonInput;
  @Input() serviceUserId: string;
  @Input() qrExpiryString: string;
  @Input() auth: any;

  buttonDisabled = true;
  showPINError = false;
  public email: string;
  public testPIN: string;
  public pin: number;
  public step: number;
  public userId: string;
  public IOS: boolean;
  constructor(
    public modalController: ModalController,
    public backend: BackendService,
    private observableEvents: ObservableEventsService,
    public platform: Platform,
    private dialogs: DialogService,
    private utils: UtilsService,
  ) {
    this.step = 0;
    this.IOS = platform.is("ios");
  }

  public dismiss() {
    return this.dialogs.closeModal(this.modalController);
  }

  checkEmail() {
    this.buttonDisabled = !EMAIL_REGEX.test(this.emailInput.value as string);
  }

  checkPIN() {
    this.showPINError = false;
    let pin = this.pinInput.value as string;
    if (pin.length !== 6) {
      return;
    }
    // deal with e2e test special case
    if (pin === "000000") {
      pin = this.testPIN;
    }
    return this.auth
      .onsiteAuth(pin, this.serviceUserId, this.userId)
      .then(() => {
        return this.dismiss();
      })
      .catch((err) => {
        if (err.error?.message === "Invalid PIN") {
          this.showPINError = true;
        } else {
          this.observableEvents.publish("displayError", err.error?.message || err.message);
          return this.dismiss();
        }
      });
  }

  setUpOnsiteAccess(position, qrCodeExpires?: Date) {
    const myHeaders: ISimpleHeader = {};
    myHeaders["Content-Type"] = "application/json";
    let body: any = {
        email: this.emailInput.value,
        client: this.serviceUserId,
        location: { latitude: position.coords.latitude, longitude: position.coords.longitude },
      };
    if (qrCodeExpires) {
      body.qrCodeExpires = qrCodeExpires;
    }

      this.backend.performHttpPostWithHeadersProvided(
        "auth/onsite-access",
        body,
        myHeaders,
        NoNetworkAction.Null
      )
      .then((resp) => {
        if (resp) {
          this.userId = resp.user;
          this.testPIN = resp.pin;
          this.step = this.step + 1;
        }
      })
      .catch((err) => {
        const errMessage = err.baseMsg || err.error;
        this.observableEvents.publish("displayError", errMessage);
      });
  }

  logEmail() {
    // Check we aren't running tests, as location checking causes problems in Electron which is what Cypress CLI uses
    if (this.emailInput.value !== "test@test.xyz") {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          let expiryDate: Date;
          if (this.qrExpiryString) {
            expiryDate = this.utils.qrExpiryStringToDate(this.qrExpiryString);
          }
          void this.setUpOnsiteAccess(position, expiryDate);
        },
        (e) => {
          if (e.constructor.name === "PositionError") {
            if (e.code === 1 && e.message === "Illegal Access") {
              return this.observableEvents.publish(
                "displayError",
                "You must enable location services to access the data, so we can ensure you are at the person's home"
              );
            } else {
              throw e;
            }
          } else {
            throw e;
          }
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 3000,
        }
      );
    } else {
      this.setUpOnsiteAccess({ coords: { latitude: 0, longitude: 0 } });
    }
  }
}
