/* eslint-disable no-console */
import { LoadingController, ModalController, Platform } from "@ionic/angular";
import { Injectable, OnInit } from "@angular/core";

import * as Sentry from "@sentry/capacitor";

import upperFirst from "lodash-es/upperFirst";

import { BackendService, ISimpleHeader, NoNetworkAction, Status } from "../../services/backend.service";
import { BackendProvider, Collection, URLType } from "../../lib/backendProvider";
import { PersonProviderService } from "./person-provider.service";
import { CacheKeyPrefix } from "../../lib/providerWithCaching";
import { ReadCacheService } from "../../services/readCacheService";
import { TagOwner } from "./tag-owner-provider.service";
import { RELEASE } from "../../services/release";
import { ObservableEventsService } from "../../services/observableEventsService";
import { IOrgCustomFormUsage, IOrgSettings, User, UserRole } from "../../models/people/user";
import { WriteCacheService } from "../../services/writeCacheService";
import { SingletonProvider } from "../../lib/singletonProvider";
import { IonicStorageService } from "../../services/ionicStorageService";
import { ERR_SUPPORT_INFORMED, ExpectedError, LoggedOutError, RCErrorHandler } from "../../error.handler";
import { UnauthenticatedLoginPage } from "../../modals/unauthenticated-login/unauthenticated-login.page";
import { ScanResult } from "@capacitor-community/barcode-scanner";
import { LogLvl } from "../../lib/logger";
import { getTZOffset } from "../../services/utilsService";
import { CarerDetailDisplayEvent } from "../../models/events/carerDetailDisplayEvent";
import { DOMAIN } from "../../../environments/environment";

interface LoginCredentials {
  username: string;
  password: string;
  phone?: string;
  system?: string;
  extra?: any;
}

interface IPlaitAuthorised {
  auth: AuthService;
}

// this class is duplicated in Plait
export class Pseudonyms {
  public clientLower: string;
  public careWorkerLower: string;
  public runLower: string;

  public clientLowerPlural: string;
  public careWorkerLowerPlural: string;
  public runLowerPlural: string;

  public clientTitle: string;
  public careWorkerTitle: string;
  public runTitle: string;

  public clientTitlePlural: string;
  public careWorkerTitlePlural: string;
  public runTitlePlural: string;

  constructor(org?: IOrgSettings) {
    // The three defaults used here should be kept in sync with $PlaitConsts in the client
    const client = org?.clientDescription || "Client";
    const careWorker = org?.careworkerDescription || "Care Worker";
    const run = org?.runDescription || "Run";

    this.clientLower = client.toLowerCase();
    this.careWorkerLower = careWorker.toLowerCase();
    this.runLower = run.toLowerCase();

    this.clientLowerPlural = this.pluralise(this.clientLower);
    this.careWorkerLowerPlural = this.pluralise(this.careWorkerLower);
    this.runLowerPlural = this.pluralise(this.runLower);

    this.clientTitle = upperFirst(client);
    this.careWorkerTitle = upperFirst(careWorker);
    this.runTitle = upperFirst(run);

    this.clientTitlePlural = this.pluralise(this.clientTitle);
    this.careWorkerTitlePlural = this.pluralise(this.careWorkerTitle);
    this.runTitlePlural = this.pluralise(this.runTitle);
  }

  private pluralise(str: string): string {
    // good enough for now
    return str + "s";
  }
}

abstract class UserCache extends SingletonProvider<User> {
  protected getDefault(): User {
    // there is no default - if we have nothing in the cache, our .data should be undefined until an authenticated login takes place
    return undefined;
  }
  get user(): User {
    return this.data;
  }
  set user(user: User) {
    this.data = user;
  }
}

class OfflineLoginCache extends UserCache {
  public get storageKey() {
    return "u";
  }
}

class ReloadUserCache extends UserCache {
  public get storageKey() {
    return "ru";
  }
}

@Injectable({
  providedIn: "root",
})
export class AuthService extends BackendProvider<TagOwner> implements OnInit {
  // if the user logs in using a tag, this cache will store their record so this can be used in case their next log in
  // - using the same tag - is done while offline.
  public offlineLoginCache: OfflineLoginCache;

  // used to store the logged-in user's record so this can be reintroduced in case of a page reload (which causes
  // a new instance of all services - including this one - to be introduced, therefore losing all state).  will be
  // cleared on log out.
  public reloadUserCache: ReloadUserCache;

  public userInfo: User;

  // Initialise with the defaults.  This will be replaced later, once we have a logged-in user with an organisation.
  public pseudonyms = new Pseudonyms();

  private deviceUUID: string;
  private doDebugAuth = false;

  public static getUserId(caller: IPlaitAuthorised, dets: string): string {
    return caller.auth.userInfo._id;
  }

  debugAuth(msg: string) {
    if (this.doDebugAuth) {
      console.log(msg);
    }
  }

  constructor(
    backEnd: BackendService,
    errorHandler: RCErrorHandler,
    observableEvents: ObservableEventsService,
    readCache: ReadCacheService<TagOwner>,
    writeCache: WriteCacheService,
    private ionicStorage: IonicStorageService,
    private loadingCtrl: LoadingController,
    private modalController: ModalController,
    private personProvider: PersonProviderService,
    private platform: Platform
  ) {
    super(backEnd, errorHandler, observableEvents, readCache, writeCache, CacheKeyPrefix.None, Collection.None);
    this.offlineLoginCache = new OfflineLoginCache(ionicStorage, errorHandler);
    this.reloadUserCache = new ReloadUserCache(ionicStorage, errorHandler);
    observableEvents.subscribe("auth:onOnline", (creds) => {
      this._onReconnect(creds).catch((e) =>
        this.errorHandler.handleUnexpected(e, ["AuthService", "auth:onOnline", "_onReconnect"])
      );
    });
  }

  ngOnInit() {
    this.initialise().catch((e) => this.errorHandler.handleUnexpected(e, ["AuthService", "initialise"]));
  }

  private async initialise() {
    if (this.doDebugAuth) {
      // we are in development - can log in without NFC, which speeds up refresh
      const token = await this.ionicStorage.getRaw<string>("token");
      if (token) {
        // As we are in development mode, we can safely tell _getUser that we have logged in using a tag (whether or
        // not we actually have).
        await this._getUser(true);
        this.backEnd.backendStatus(Status.On);
      }
    }
  }

  // Any pages that require the user to be logged-in should call checkLoggedInOnPageLoad() at the beginning of the
  // appropriate initialisation lifecycle hook(s) (ngOnInit,ionViewDidEnter etc.).
  // If this.userInfo is not assigned, we will attempt to initialise it from the reloadLoginCache, enabling pages to be
  // reloaded (which causes a new AuthService to be introduced, without a value for this.userInfo initially), without
  // the user being thrown out to the login page.
  // If - even after checking this.reloadLoginCache - the user is found to be NOT logged in (as might happen if they
  // have timed-out, or hit the back button after logging-out manually, for example), then unless redirectToLoginPageIfNoUser
  // is false, we will throw them out to the login page (with an error displayed in a card at the top of the page).
  // In this scenario, we return false, indicating to our caller that it should not proceed with page initialisation.
  public async checkLoggedInOnPageLoad(redirectToLoginPageIfNoUser = true): Promise<boolean> {
    if (this.userInfo) {
      return true;
    }
    if (!this.reloadUserCache) {
      return false; // shouldn't happen
    }
    // this.userInfo has no value, but perhaps this is because we're a brand new instance of AuthService, following
    // a page reload.  In that scenario, this.reloadLoginCache should have the logged-in user cached:
    await this.reloadUserCache.retrieveData();
    if (this.reloadUserCache.user) {
      // finding this.reloadLoginCache.user having not found this.userInfo earlier suggests that we really are
      // responding to a page reload.  in this scenario, this.backEnd will be a brand new instance of BackendService,
      // and won't know whether we are online or not until we...
      await this.backEnd.restoreCachedNetworkStatus();
      if (this.backEnd.isOnline()) {
        // set everything up to look and operate as we should while we are online.  If we're not online, the same
        // call will be made later when reconnection is detected.  (see _onReconnect())
        await this.backEnd.onOnline();
      }
      this.completeLoginForUser(this.reloadUserCache.user, true);
    }
    if (redirectToLoginPageIfNoUser) {
      return this.checkLoggedIn();
    } else {
      return !!this.userInfo;
    }
  }

  // check that the user is logged in, and redirect them to the login page (with an error displayed in a card at the top
  // of the page) if they are not.
  public checkLoggedIn(): boolean {
    if (this.userInfo) {
      return true;
    } else {
      this.observableEvents.publish("auth:unauthorised");
      return false;
    }
  }

  public setDeviceUUID(uuid: string): void {
    this.debugAuth(`UUID is ${uuid}`);
    this.deviceUUID = uuid;
  }

  public getDeviceUUID(): string {
    return this.deviceUUID;
  }

  public async login(username: string, password: string): Promise<string | void> {
    try {
      await this._authenticate({ username, password });
      await this._onServerAuthenticate(false);
    } catch (err) {
      return "error logging in! " + err;
    }
  }

  public logout(): void {
    this.userInfo = undefined;
    this.backEnd.backendStatus(Status.NoAuth);
    this.ionicStorage
      .removeItem("token")
      .catch((e) => this.errorHandler.handleUnexpected(e, ["AuthService", "logout", "ionicStorageService.removeItem"]));
    // now we are logged-out, we need to clear the item in storage that remembers the currently logged-in user so this
    // can be restored in case of a page reload
    this.reloadUserCache.user = undefined;
    this.reloadUserCache
      .updateStorage()
      .catch((e) => this.errorHandler.handleUnexpected(e, ["AuthService", "logout", "reloadLoginCache.updateStorage"]));
  }

  public setServiceUser(newOffset: number) {
    const user = this.userInfo;
    if (!user || newOffset === this.userInfo.clientOffset) {
      return;
    }
    user.clientOffset = undefined;
    const serviceUserId = user.clientsHasAccessTo[newOffset]._id;
    this.personProvider
      .getRecord(serviceUserId)
      .then((person) => {
        if (!person) {
          // this can happen if the cache is empty and the backend request failss.  there is no sensible action we
          // can take here in response to this - the page where the service user's details are first required (which
          // would almost certainly be home-scheduling-client) needs to take its own action when this case is detected
          this.logger.log(`setServiceUser failed to retrieve client details for ${serviceUserId}`, LogLvl.Error);
        } else {
          user.clientOffset = newOffset;
          user.client = person;
        }
      })
      .catch((e) => this.errorHandler.handleUnexpected(e, ["AuthService", "setServiceUser", "getPersonDets"]));
  }

  public isUnrestricted(): boolean {
    return !this.userInfo?.sysUserData?.roles.includes("restricted");
  }

  public isAdmin(): boolean {
    return this.userInfo?.sysUserData?.roles.includes("admin");
  }

  public allowFindClient(): boolean {
    return this.userInfo?.orgObj?.allowClientSearchForAll && !this.schedulingMode();
  }

  private async handleScan(creds: LoginCredentials, offlineLogin: () => Promise<void>, doShowLoading = true) {
    if (doShowLoading) {
      await this._showLoading();
    }
    try {
      const data = await this._authenticate(creds);
      if (data) {
        await this._onServerAuthenticate(true);
      } else {
        // If we got nothing back (but yet, no exception occurred), then we have no
        // network (see use of NoNetworkAction.Null in _authenticate).
        // In this case, attempt offline login
        await offlineLogin();
      }
    } catch (err) {
      if (/Http failure response for .+\/auth\/local.+: 504 Gateway Timeout/.test(err.message)) {
        // This is what happens when we can't contact the back end
        // Try and log in offline...
        await offlineLogin();
      } else if (["QRCode login must be from correct device", "Invalid device"].includes(err.error?.message)) {
        return this.observableEvents.publish("displayError", "You are not set up for this device");
      } else if (err.baseMsg.startsWith("Unauthenticated onsite ")) {
        const componentProps: any = {
          serviceUserId: creds.username,
          auth: this,
        }
        const parts = creds.extra?.content?.split('@');
        if (parts.length === 2) {
          componentProps.qrExpiryString = parts[1];
        }
        const modal = await this.modalController.create({
          component: UnauthenticatedLoginPage,
          componentProps
        });
        return await modal.present();
      } else {
        console.log("Invalid Code" + JSON.stringify(err, null, 2));
      }
    }
  }

  public async qrAuth(barcodeData: ScanResult, doShowLoading = true) {
    let userId: string;
    if (barcodeData.content.match(/^[0-9a-f]{24}$/)) {
      userId = barcodeData.content;
    } else if (barcodeData.content.match(/^[0-9a-f]{24}@[\d]{10}$/)) {
      userId = barcodeData.content.slice(0, 24);
    } else {
      return; // Not one of ours, for sure
    }
    const creds: LoginCredentials = {
      username: userId,
      password: "_QR_",
      phone: this.deviceUUID,
      extra: barcodeData,
    };
    this.handleScan(
      creds,
      async () => {
        await this.offlineLoginCache.retrieveData();
        const user = this.offlineLoginCache.user;
        if (user && user._id === barcodeData.content) {
          await this.ionicStorage.setRaw("OFFLINE" + JSON.stringify(creds), "token");
          this.completeLoginForUser(user);
        }
      },
      doShowLoading
    ).catch((e) => this.errorHandler.handleUnexpected(e, ["AuthService", "qrAuth", "handleScan"]));
  }

  public nfcAuth(person: any, tagId: string, doShowLoading = true) {
    const creds: LoginCredentials = {
      username: person._id,
      password: tagId,
      phone: this.deviceUUID,
    };
    return this.handleScan(
      creds,
      async () => {
        const tagData = await this.readCache.get(
          `${CacheKeyPrefix.TagOwner}.${tagId}`,
          this.cacheAgeLimit,
          this.cacheRefreshAfter
        );
        if (tagData) {
          await this.offlineLoginCache.retrieveData();
          const user = this.offlineLoginCache.user;
          if (user && user._id === tagData.obj._id) {
            await this.ionicStorage.setRaw("OFFLINE" + JSON.stringify(creds), "token");
            this.completeLoginForUser(user);
          }
        }
      },
      doShowLoading
    );
  }

  public async storeFakeNFCTagId(tagId: string): Promise<void> {
    if (this.userInfo) {
      await this.readCache.store(
        {
          _id: this.userInfo._id,
          careWorker: { isCareWorker: true },
        },
        `${CacheKeyPrefix.TagOwner}.${tagId}`
      );
    }
  }

  public async onsiteAuth(pin: string, serviceUserId: string, userId: string) {
    const creds: LoginCredentials = {
      username: userId,
      password: pin,
      phone: this.deviceUUID,
      extra: serviceUserId,
    };
    if (await this._authenticate(creds)) {
      await this._onServerAuthenticate(false, serviceUserId);
    }
  }

  // we have come online, having previously performed an offline login (which is done by logging in - while offline
  // - using the same tag that was used to login earlier while in signal).  now we are back online,
  // we will perform a full authentication.
  private async _onReconnect(token: string) {
    console.log("onreconnect ", token);
    const creds: LoginCredentials = JSON.parse(token);
    const data = await this._authenticate(creds, true);
    if (data) {
      console.log("Authenticated after reconnect : " + JSON.stringify(data));
      await this._getUser(true);
      this.backEnd.backendStatus(Status.On);
    } else {
      // We have reconnected to the network, but not the server (maybe it is down)
    }
  }

  private _setCredentials(creds: LoginCredentials): any {
    creds.system = "mob";
    if (this.platform.is("cordova")) {
      if (this.platform.is("android")) {
        creds.system += "A ";
      } else {
        creds.system += "I ";
      }
    } else {
      creds.system += "? ";
    }
    creds.system += RELEASE;
    return creds;
  }

  private async _showLoading(): Promise<void> {
    const res = await this.loadingCtrl.create({ message: "Logging in<br>Please wait…" });
    await res.present();
    const clearLoading = setInterval(() => {
      res.dismiss().catch((e) => this.errorHandler.handleUnexpected(e, ["AuthService", "_showLoading", "res.dismiss"]));
      clearInterval(clearLoading);
    }, 250);
  }

  private async _authenticate(creds: LoginCredentials, backgroundMode = false): Promise<any> {
    if (!backgroundMode) {
      this.logout();
      await this._showLoading();
    }
    const myHeaders: ISimpleHeader = {};
    const data = await this.backEnd.performHttpPostWithHeadersProvided(
      "auth/local",
      this._setCredentials(creds),
      myHeaders,
      NoNetworkAction.Null
    );
    if (data && data.token) {
      await this.backEnd.stashToken(data.token);
      this.observableEvents.publish("backend:auth-nudge"); // Start the backend
    }
    return data;
  }

  private async _onServerAuthenticate(usingTagOrQR: boolean, onsiteId?: string) {
    try {
      const user = await this._getUser(usingTagOrQR, onsiteId);
      if (user) {
        this.completeLoginForUser(user);
      }
    } catch (err) {
      console.log("Could not get user data: ", err.message);
      if (!(err instanceof LoggedOutError)) {
        console.log(err.stack);
      }
    }
  }

  private async _getUser(usingTagOrQR: boolean, onsiteId?: string): Promise<User> {
    let url = "api/users/me";
    if (onsiteId) {
      url += `?client=${onsiteId}`;
    }
    const data: User = await super.getFromBackEnd(URLType.Custom, url);
    if (data) {
      // All org API calls now have orgObj instead of org, but we need to support both for the time being  (Nov 2023).
      if ((data as any).org) {
        Object.defineProperty(data, "orgObj", Object.getOwnPropertyDescriptor(data, "org"));
        delete data["org"];
      }
      if (data.clientsHasAccessTo?.length > 0) {
        data.role = onsiteId ? UserRole.Emergency : UserRole.Client;
        data.clientOffset = data.clientsHasAccessTo.length === 1 ? 0 : undefined;
      } else {
        data.role = UserRole.CareWorker;
      }
      if (usingTagOrQR) {
        // if they are logging-in with a tag, cache the user info to enable them to log in again later (using the
        // same tag), even when they are offline.  to make that possible, the offlineLoginCache item will not be
        // removed when the user logs out.
        this.offlineLoginCache.user = data;
        await this.offlineLoginCache.updateStorage();
      }
    }
    // also cache the user in reloadLoginCache so this will be available if a page reload happens (which causes
    // a brand new auth.service to be introduced).  this cache entry WILL be cleared when the user logs out
    // (either actively, or forcibly, following a timeout).  NB: we do this one even when data has no value - this
    // serves to clear the cache if we get nothing from the backend.
    this.reloadUserCache.user = data;
    await this.reloadUserCache.updateStorage();
    return data;
  }

  private initialiseSentry(data: User) {
    const sentryContext: any = { id: data._id, username: `${data.givenName || ""} ${data.familyName}` };
    if (data.emailList && data.emailList.length > 0) {
      sentryContext.email = data.emailList[0].email;
    }
    Sentry.configureScope((scope) => {
      scope.setUser(sentryContext);
    });
  }

  private completeLoginForUser(user: User, stayOnCurrentPage = false): void {
    // we either have an authenticated login or a cached one.  Stash info that we want Sentry to have.
    this.initialiseSentry(user);
    this.userInfo = user;
    const userOrg: IOrgSettings = user.orgObj;

    // Set action level allow leave out from ogLeaveOut if it is not set
    userOrg.medicationActions.forEach((a) => {
      if (!a.leaveOutMeds) {
        a.leaveOutMeds = a.description === "Administer" ? userOrg.leaveOutMeds : "Log as missed";
      }
    });
    this.pseudonyms = new Pseudonyms(userOrg);
    // Check that the user has the "right" timezone for the organisation.  If not we will display a warning.
    if (new Date().getTimezoneOffset() !== getTZOffset(userOrg.timezone)) {
      if (user.role === UserRole.CareWorker) {
        setTimeout(() => {
          throw new ExpectedError(
            `Your device is not in the same timezone as where the care is being delivered (${new Date().getTimezoneOffset()} vs ${getTZOffset(
              userOrg.timezone
            )})<br /><br />
              Please change your device's timezone to ${userOrg.timezone}.<br /><br />
              You will then be able to log in.`
          );
        }, 1000);
        return;
      } else {
        setTimeout(() => {
          this.observableEvents.publish(
            "showError",
            "Your device is not in the same timezone as where the care is being delivered.  You will be shown times in your local time." +
              "  To see the time of the visits in the timezone of the care, please change your device's timezone."
          );
        }, 2000);
      }
    }
    this.observableEvents.publish("auth:authenticated", {
      skipHomePageRedirect: stayOnCurrentPage,
    });
  }

  public schedulingMode(): boolean {
    const org = this.userInfo?.orgObj;
    if (org) {
      return !(org.schedulingSystem || org.hideSchedulesOnMobile);
    } else {
      return false;
    }
  }

  public async authoriseAndBeginFormEntry(
    form: IOrgCustomFormUsage,
    event: CarerDetailDisplayEvent
  ): Promise<string | undefined> {
    let eventId: string;
    let recurId: number;
    const expressed = event.event;
    if (expressed.eventId) {
      eventId = expressed.eventId;
      recurId = 0;
    } else {
      eventId = expressed.parentId;
      recurId = expressed.instanceStart.valueOf();
    }
    const url = `form/setUpPocAccess/${form.formId}/${eventId}/${recurId}`;
    const res = await this.getFromBackEnd(URLType.Extended, url);
    if (!res) {
      // the call that this.getFromBackEnd(...) (indirectly) makes to handleHttpError causes us to receive nothing
      // when the user is offline.
      return (
        "Sorry, but you appear to be offline, and it is currently only possible to do this when you have a network connection.  " +
        "Please try again when you are back in signal."
      );
    }
    try {
      const messageListener = (messageEvent: MessageEvent<string>) => {
        if (messageEvent.origin !== DOMAIN) {
          return;
        }
        const message = messageEvent.data;
        if (typeof message !== "string" || !message.startsWith("Point of care form entry")) {
          return;
        }
        window.removeEventListener("message", messageListener);
        const parts = message.split("|");
        let title: string;
        let body: string;
        if (parts[1] === "completed") {
          title = "Thank you!";
          body = `${parts[2]} details have been submitted to the office who will be in touch if they require any further information from you.`;
        } else {
          title = "Form entry cancelled";
          body = `The ${parts[2]} details have <b><i>not</i></b> been submitted.`;
        }
        this.observableEvents.publish("displayError", { title, body });
      };
      window.addEventListener("message", messageListener);
      window.open(DOMAIN + res.url, "_blank");
    } catch (e) {
      Sentry.captureException(e);
      return (
        `Sorry, but something went wrong when we tried to display the ${form.formName} form.  ` +
        `${ERR_SUPPORT_INFORMED}, but as this might be a temporary problem, or one that is particular to your device, please try again, ` +
        `and if the problem continues to occur, let your office know about it.`
      );
    }
  }
}
