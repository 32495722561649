import { IIdentified } from "../../lib/backendProvider";
import { IPersonName } from "../../providers/data/event-provider.service";
import { NamedPerson } from "./current-client";
import { FullPerson } from "./fullPerson";

export enum UserRole {
  CareWorker,
  Client,
  Emergency,
}

export interface User extends IIdentified, IPersonName {
  sex?: string;
  sysUserData?: any;
  emailList?: any[];
  orgObj: IOrgSettings;
  role?: UserRole;

  // For a relative login only:
  clientsHasAccessTo?: NamedPerson[]; // all the clients who they have access to
  clientOffset?: number; // the index of clientsHasAccessTo they are currently looking at
  client?: FullPerson; // the client who they are currently looking at
}

export const enum LoggingMethod {
  Manual = "Manual",
  NFC = "NFC",
  QRCode = "QR Code",
}

export const enum LoggingMethodPromptForReason {
  Always = "Always",
  Never = "Never",
  IfAdjustmentExceedsThreshold = "If Adjustment Exceeds Threshold",
}

type ILeaveOutOptions = "Allow" | "Allow for current session" | "Log as missed";

export interface IMedicationAction {
  description: string;
  trackMeds: boolean;
  leaveOutMeds?: ILeaveOutOptions;
  displayAction: boolean;
}

type FormAppliesTo =
  | "contact event"
  | "client-only event"
  | "careworker-only event"
  | "client review"
  | "careworker review"
  | "observation"
  | "client"
  | "careworker"
  | "purchaser"
  | "contact"
  | "plait user"
  | "organisation";

// this is a copy of the same-named interface in Plait's plait-global-types.d.ts
export interface IOrgCustomFormUsage {
  formId: string;
  cannotBeUsedBefore?: Date;
  cannotBeUsedAfter?: Date;
  formName?: string; // this is a decoration only added for instances of IPOCClientUserOrgObj by the /me call
  appliesToOpts?: FormAppliesTo[];
}

export interface ILoggingMethodOpts {
  method: LoggingMethod;
  defEventStartAdj: number;
  defEventFinishAdj: number;
  advanceLoggingLimit: number;
  retrospectiveLoggingLimit: number;
  allowAdjustmentsOfUpTo: number;
  promptForReason: LoggingMethodPromptForReason;
  promptForReasonThreshold: number;
  promptText: string;
  defaultReasons: string;
  geolocation?:
    | "Ignore"
    | "Record checkin location"
    | "Record distance from expected checkin"
    | "Prevent checkin in excess of allowed radius";
  allowedGeoFencingRadius?: number;
}

export interface IReason {
  reason: string;
}

export interface IHomePageMessage {
  name: string;
  text: string;
}

// This interface contains selected properties from the server-side Organisation model,
// specifically those included in the IPOCClientUserOrgObj type declared in Plait's plait-global-types.d.ts
export interface IOrgSettings {
  name?: string;
  clientDescription?: string;
  careworkerDescription?: string;
  runDescription?: string;
  medicationSystem?: string;
  schedulingSystem?: string;
  hideSchedulesOnMobile?: boolean;
  showClientPhotos?: boolean;
  showClientPhoneNumbers?: boolean;
  showCareWorkerPhoneNumbers?: boolean;
  logInWindowMinsEarly?: number;
  logInWindowMinsLate?: number;
  logOutWindowMinsEarly?: number;
  logOutWindowMinsLate?: number;
  logInWindowMaxMinsEarly?: number;
  logInWindowMaxMinsLate?: number;
  logOutWindowMaxMinsEarly?: number;
  logOutWindowMaxMinsLate?: number;
  earlyLogInReasons?: [IReason];
  lateLogInReasons?: [IReason];
  earlyLogOutReasons?: [IReason];
  lateLogOutReasons?: [IReason];
  allowedLoggingMethods?: [ILoggingMethodOpts];
  timezone?: string;
  homePageMessages?: [IHomePageMessage];
  reasonsMissed?: [string];
  leaveOutMeds?: ILeaveOutOptions;
  leaveOutReasons?: [string];
  historicalBookingsWindowInDays?: number;
  futureBookingsWindowInDays?: number;
  allowQRCodeLoginOnMobile?: string;
  allowClientSearchForAll?: boolean;
  eventLogMinWordsForPrimaryCarer?: number;
  eventLogMinWordsForOtherCarers?: number;
  showLastAdminIfWithin?: number;
  insistOnLocationPermission?: boolean;
  minGeolocationRetries?: number;
  onTheFlyEventType?: string;
  viewableToContacts?: string[];
  medicationActions?: IMedicationAction[];
  forms?: IOrgCustomFormUsage[];
  summaryViewCountsAsAware?: boolean;
}
