import * as Sentry from "@sentry/capacitor";

import { CacheKeyPrefix } from "../../lib/providerWithCaching";
import { ReadCacheService } from "../../services/readCacheService";
import { WriteCacheService } from "../../services/writeCacheService";
import { BackendService } from "../../services/backend.service";
import { ObservableEventsService } from "../../services/observableEventsService";
import { BackendProvider, Collection } from "../../lib/backendProvider";
import { EventTypeProviderService, IEventType } from "./event-type-provider.service";
import { PersonProviderService } from "./person-provider.service";
import { IRun, RunProviderService } from "./run-provider.service";
import { ONE_DAY, ONE_MINUTE } from "../../constants";
import { IInProgressEventLog, IInProgressTask } from "./event-updates-provider.service";
import { LogLvl } from "../../lib/logger";
import { CurrentClientProviderService, ICurrentClientType } from "./current-client-provider.service";
import { UtilsService } from "../../services/utilsService";
import { IInMemoryStore } from "../../lib/immutableBackendProvider";
import { IPerson } from "../../models/people/fullPerson";
import { RCErrorHandler } from "../../error.handler";
import { IParentEvent, ParentEventProviderService } from "./parent-event-provider.service";

export enum ExpressedEventClass {
  // these are values used at the back-end, but they're cleaned before events are passed to us, so we'll never
  // see them.  in 99% of cases, therefore, eventClass will be unassigned, but it is used for ad hoc events
  // originating from here.
  // MaterialisedInstance = "MaterialisedInstance",
  // UnmaterialisedInstance = "UnmaterialisedInstance",
  OneOff = "OneOff",
  AdHoc = "AdHoc",
}

export interface IPersonName {
  title?: string;
  givenName?: string;
  familyName?: string;
  preferredName?: string;
}

export interface IRunName {
  longName?: string;
  shortName?: string;
}

export interface IAllocation {
  person?: string;
  contract?: string;
}

export interface IActualTime {
  time: Date;
  loggingMethod: string;
  recordedAt: Date;
  recordedByThirdParty?: string;
  earlyReason?: string;
  lateReason?: string;
  loggingMethodReason?: string;
  adjustmentReason?: string;
  geoCoord?: number[];
  geoDistance?: number;
  qrDate?: Date; // When the QRCode was printed
}

export interface IInformed {
  detailView?: Date;
  summaryView?: Date;
  manual?: Date;
}

export interface ICarerAllocation extends IAllocation {
  actualStart?: IActualTime;
  actualFinish?: IActualTime;
  informed?: IInformed;
  run?: string;
}

export interface IAllocatedByRun extends IAllocation, IPersonName {}

export interface IExpressedCarerAllocation extends ICarerAllocation, IPersonName, IRunName {
  allocatedByRun?: IAllocatedByRun[];
}

// Reflects additional stuff added to completed task data before it's written to db.events
export interface ITask extends IInProgressTask {
  mins?: number;
  recordedBy?: string;
}

export interface ICareplanningStatus {
  starts?: Date; // date of the client's first careplan
}

export interface IExpressedClientAllocation extends IAllocation, IPersonName {
  tasks?: ITask[];
  cp?: ICareplanningStatus;
}

export interface IEventLog extends IInProgressEventLog {
  author: string;
  subject: string;
  submissionDate?: Date;
  reviewedBy?: string;
  reviewDate?: Date;
  wip?: boolean; // it's an interim log
}

// A cut-down re-definition of plait.IExpressedEvent, with only those fields used in this project.
// To minimise bandwidth and storage requirements, the Plait back-end only provides us with these
// fields - everything else is removed by cleanEvents() in event.assignees.expression.ts.
export interface IExpressedEvent {
  plannedStart: Date;
  plannedDuration: number;
  eventClass: ExpressedEventClass;
  type: string;
  clients: IExpressedClientAllocation[];
  carers: IExpressedCarerAllocation[];
  eventId: string;
  parentId?: string;
  comments?: string;
  instanceStart?: Date;
  logs: IEventLog[];
  // This is not provided by the backend.  It is an id used internally to uniquely identify
  // events, whether one-off or a materialised or unmaterialised instance of a regular series
  uniqueEventId: string;
  // When expression is performed with a value of true for opts.mobileRequest, the back-end will
  // determine who the primary carer is for us.  basically, it's the "first" assignee, but that
  // becomes complicated when there is a 2-carer run involved, in which case the carer with the
  // least recently updated carer allocation is considered "primary" whether they (or the other
  // carer) have checked in yet, or nor.  that calculation is done determinePrimaryCarerForEvents.
  primaryCarerId?: string;
}

export interface IEventIdentification {
  uniqueEventId: string;
  clientId: string; // we're only ever interested in the event in the context of a particular client
}

export interface IWindowedEventIdentification extends IEventIdentification {
  windowStart: number;
}

export type IClientEventIdentification = IWindowedEventIdentification;

export interface ICarerEventIdentification extends IEventIdentification {
  carerId: string;
}

export interface IWithStores {
  eventTypes: IInMemoryStore<IEventType>;
  people: IInMemoryStore<IPerson>;
  runs: IInMemoryStore<IRun>;
  parentEvents: IInMemoryStore<IParentEvent>;
  currentClients?: ICurrentClientType[];
}

export interface IExpressedEventsAndStores extends IWithStores {
  events: IExpressedEvent[];
}

export function isAdHoc(arg: IExpressedEvent): boolean {
  return arg.uniqueEventId?.slice(0, 1) === "-";
}

export abstract class BaseEventProviderService<T extends IExpressedEvent[]> extends BackendProvider<T> {
  constructor(
    backEnd: BackendService,
    errorHandler: RCErrorHandler,
    observableEvents: ObservableEventsService,
    readCache: ReadCacheService<T>,
    writeCache: WriteCacheService,
    cacheKeyPrefix: CacheKeyPrefix,
    protected utils: UtilsService,
    protected currentClientProvider: CurrentClientProviderService,
    protected eventTypeProvider: EventTypeProviderService,
    protected parentEventProvider: ParentEventProviderService,
    protected personProvider: PersonProviderService,
    protected runProvider: RunProviderService
  ) {
    super(
      backEnd,
      errorHandler,
      observableEvents,
      readCache,
      writeCache,
      cacheKeyPrefix,
      Collection.Event,
      ONE_DAY, // keep data in the cache for a maximum of 24 hours
      10 * ONE_MINUTE // used to be 30 seconds. mark agreed to 10 minutes, especially as users can swipe down to refresh if they have any doubts
    );
  }

  public async decorateEvents(events: IExpressedEvent[]): Promise<void> {
    for (const event of events) {
      await this.decorateEvent(event);
    }
  }

  private async decorateEvent(event: IExpressedEvent): Promise<void> {
    event.uniqueEventId = BaseEventProviderService.getUniqueEventId(event);
    if (event.clients) {
      for (const client of event.clients) {
        await this.decoratePersonAlloc(client);
        await this.decorateClientAlloc(client);
      }
    }
    if (event.carers) {
      for (const alloc of event.carers) {
        await this.decoratePersonAlloc(alloc);
        await this.decorateCarerAlloc(alloc);
        if (alloc.allocatedByRun) {
          for (const byRun of alloc.allocatedByRun) {
            await this.decoratePersonAlloc(byRun);
          }
        }
      }
    }
  }

  private async decoratePersonAlloc(alloc: IExpressedClientAllocation | IExpressedCarerAllocation): Promise<void> {
    if (alloc.person) {
      const personObj = await this.personProvider.getRawPersonDets(alloc.person);
      alloc.familyName = personObj.familyName;
      alloc.givenName = personObj.givenName;
      alloc.title = personObj.title;
      alloc.preferredName = personObj.preferredName;
    }
  }

  private async decorateClientAlloc(alloc: IExpressedClientAllocation): Promise<void> {
    let starts: Date;
    if (alloc.person) {
      const personObj = await this.personProvider.getRawPersonDets(alloc.person);
      const publishedCareplans = personObj.client.careplanList?.filter((c) => c.effectiveDate);
      if (publishedCareplans?.length > 0) {
        publishedCareplans.sort((a, b) => a.effectiveDate.valueOf() - b.effectiveDate.valueOf());
        starts = publishedCareplans[0].effectiveDate;
      }
      alloc.cp = { starts };
    }
  }

  private async decorateCarerAlloc(alloc: IExpressedCarerAllocation): Promise<void> {
    if (alloc.run) {
      const run = await this.runProvider.getRecord(alloc.run);
      alloc.longName = run.longName;
      alloc.shortName = run.shortName;
    }
  }

  public async detachAndCacheImmutableData(eventsAndStores: IExpressedEventsAndStores): Promise<IExpressedEvent[]> {
    const promises: Promise<any>[] = [
      this.eventTypeProvider.cacheRecords(eventsAndStores.eventTypes),
      this.personProvider.cacheRecords(eventsAndStores.people),
      this.runProvider.cacheRecords(eventsAndStores.runs),
      this.parentEventProvider.cacheRecords(eventsAndStores.parentEvents),
    ];
    if (eventsAndStores.currentClients) {
      promises.push(this.currentClientProvider.cacheCurrentClients(eventsAndStores.currentClients));
    }
    await Promise.all(promises);
    return eventsAndStores.events;
  }

  protected abstract cacheDesc(): string;

  public async retrieveCachedEvent(
    cacheId: string,
    uniqueEventId: string,
    additionalContextForLog?: string
  ): Promise<IExpressedEvent> {
    const comps = this.getUniqueEventIdComponents(uniqueEventId);
    const eventDesc = comps
      ? `${this.utils.formatDateMed(comps.instanceStart, true, true)} instance of series ${comps.parentId}`
      : `event ${uniqueEventId}`;
    let log = `Retrieving ${eventDesc} from cache for ${this.cacheDesc()} ${cacheId}`;
    if (additionalContextForLog) {
      log += ` (${additionalContextForLog})`;
    }
    this.logger.log(log, LogLvl.Light);
    const cached = await this.getCached(this.getCacheKey(cacheId));
    const events = cached?.obj;
    if (!events) {
      // we used to do this -> throw new Error(`No cached events found for id ${cacheId}`);
      // but I don't see any benefit in doing that.  by returning nothing, our caller can decide how to proceed.
      // we know this happens, and not because of a logic area but probably due to an earlier cache write failing
      // (e.g., because of storage issue, or one of the many iOS issues occurring when the app is coming into /
      // out of a dormant state)
      // keep the message here generic as we want these to be grouped in Sentry on the basis that we're really
      // just monitoriing how often this happens and probably won't even investigate further
      Sentry.captureMessage(`retrieveCachedEvent failed to find cached events array for ${this.cacheDesc()}`);
      return;
    }
    let event = events.find((e) => (e.uniqueEventId || BaseEventProviderService.getUniqueEventId(e)) === uniqueEventId);
    const error = `No cached event found for id ${cacheId} matching id ${uniqueEventId}`;
    if (!event) {
      this.logger.log(error, LogLvl.Error);
      if (comps) {
        event = events.find(
          (e) => e.parentId === comps.parentId && e.instanceStart.valueOf() === comps.instanceStart.valueOf()
        );
      }
      if (event) {
        this.logger.log("Recovered from error condition by finding unmaterialised instance", LogLvl.Light);
      } else {
        if (comps) {
          this.logger.log(
            `Failed to find unmaterialised instance - ${comps.parentId}, ${comps.instanceStart}`,
            LogLvl.Light
          );
        } else {
          this.logger.log(`Could not split ${uniqueEventId} into components`, LogLvl.Heavy);
        }
      }
    }
    if (!event) {
      this.logger.log(
        `The cache currently contains: ${events.map((e) => BaseEventProviderService.getUniqueEventId(e)).join(", ")}`,
        LogLvl.Error
      );
      // keep the message here generic as we want these to be grouped in Sentry on the basis that we're really
      // just monitoriing how often this happens and probably won't even investigate further
      Sentry.captureMessage(`retrieveCachedEvent failed to find event in cached events array for ${this.cacheDesc()}`);
      return;
    }
    event.uniqueEventId = BaseEventProviderService.getUniqueEventId(event);
    return event;
  }

  public static getUniqueEventId(event: IExpressedEvent): string {
    return event.eventId || event.parentId + "-" + event.instanceStart.valueOf();
  }

  public static findEventInList(event: IExpressedEvent, list: IExpressedEvent[]): IExpressedEvent {
    return list.find(
      (e) =>
        (event.eventId && e.eventId && event.eventId === e.eventId) || // don't just do equality check as we don't want undefined to match
        (event.parentId === e.parentId && event.plannedStart.valueOf() === e.plannedStart.valueOf())
    );
  }

  protected getUniqueEventIdComponents(uniqueEventId: string): { parentId: string; instanceStart: Date } {
    const splitIndex = uniqueEventId.indexOf("-");
    if (splitIndex > 0) {
      return {
        parentId: uniqueEventId.substring(0, splitIndex),
        instanceStart: new Date(parseInt(uniqueEventId.substring(splitIndex + 1), 10)),
      };
    }
  }
}
