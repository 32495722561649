import { DebugLvl } from "../app/constants";
export const BETA = false;
let host = window.location.host.split('.');
let mapDomainFromOrigin = false;
if (host[1] === 'plaitpoc' && host[2] === 'org') {
    mapDomainFromOrigin = true;
}

export const DOMAIN = mapDomainFromOrigin ? `https://${['www','next','next'][['www', 'next', 'main'].indexOf(host[0])]}.plaitapp.org` : "https://www.plaitapp.org";

export const environment: any = {
  production: true,
  providerDebuggingLevel: BETA ? DebugLvl.Light : DebugLvl.Error,
};

// List of developer phones that don't try to upgrade (in fact downgrade) when testing
export const devPhones = ["c1f2147e55dd7445", "7d4e3edaf52d6ab7", "2AB53C64-9045-44D0-B324-242CCA7D5E46"];
// export const devPhones = [];
